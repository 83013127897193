import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Paper,
  Button,
  Typography,
  Stack,
  List,
  ListItem,
  ListItemText,
  Breadcrumbs,
  Link,
  Divider,
} from "@mui/material";
import {
  NavigateNextOutlined,
  Add as AddIcon,
  Image as ImageIcon,
} from "@mui/icons-material";
import { ClusterType } from "./bulkquestion/types";
import { QuestionEditor } from "./bulkquestion/QuestionEditor";
import { QuestionPreview } from "./bulkquestion/QuestionPreview";
import mammoth from "mammoth";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { allApis } from "../../request/config";
import apiRequest from "../../request/apiRequest/apiRequest";
import { useAppSelecter } from "../../hooks/storeHooks";
import { ObjectValues } from "../login";

interface ParsedQuestion {
  id: string;
  text: string;
  options: {
    text: string;
    html: string;
  }[];
  correctAnswer: number;
  explanation: string;
  solutionHtml: string;
  cluster: string;
}

interface Question {
  id: string;
  text: string;
  questionHtml: string;
  options: string[];
  correctAnswer: number;
  explanation: string;
  solutionHtml: string;
  image?: string;
  source?: string;
  examDate?: string;
}

interface Cluster {
  id: string;
  name: string;
  questions: Question[];
}

export interface BulkQuestionsFormProps {
  dialogRef: React.MutableRefObject<{ onSubmitForm?: () => void }>;
  onSubmit: (questions: Question[]) => void;
  onClose: () => void;
}

export default function BulkQuestionsForm({
  dialogRef,
  onSubmit,
  onClose,
}: BulkQuestionsFormProps) {
  const [clusters, setClusters] = useState<Cluster[]>([]);
  const [selectedClusterIndex, setSelectedClusterIndex] = useState(0);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [editorElements, setEditorElements] = useState<NodeListOf<Element>>();
  const [hasFileUploaded, setHasFileUploaded] = useState(false);
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    type: "info" | "confirm";
    title: string;
    message: string;
  }>({
    open: false,
    type: "confirm",
    title: "",
    message: "",
  });
  const [totalQuestions, setTotalQuestions] = useState(0);

  const userData = useAppSelecter(
    (state: ObjectValues) => state.userSlice.loggedUser
  );

  const ref = useRef<HTMLDivElement>();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const questionListRefs = useRef<(HTMLDivElement | null)[]>([]);
  const editorRefs = useRef<(HTMLDivElement | null)[]>([]);
  const previewRefs = useRef<(HTMLDivElement | null)[]>([]);
  const inputRefs = useRef<{
    [key: string]: HTMLInputElement | HTMLTextAreaElement | null;
  }>({});
  const updateCompletedRef = useRef(false);

  const createEmptyQuestion = (): Question => ({
    id: Math.random().toString(36).substr(2, 9),
    text: "",
    questionHtml: "",
    options: ["", "", "", ""],
    correctAnswer: -1,
    explanation: "",
    solutionHtml: "",
  });

  const createEmptyCluster = (): ClusterType => ({
    id: Math.random().toString(36).substr(2, 9),
    name: `Cluster ${clusters.length + 1}`,
    questions: [createEmptyQuestion()],
  });

  const isValidQuestion = (question: Question) => {
    return (
      question.text.trim() !== "" &&
      question.options.length > 1 &&
      question.options.every((opt) => opt.trim() !== "") &&
      question.correctAnswer !== undefined &&
      question.correctAnswer >= 0 &&
      question.correctAnswer < question.options.length
    );
  };

  const isSingleQuestionPerCluster = () => {
    return clusters.every(
      (cluster) => cluster.questions.filter(isValidQuestion).length === 1
    );
  };

  const handleOpen = () => {
    if (isSingleQuestionPerCluster()) {
      setDialogState({
        open: true,
        type: "info",
        title: "Already Separated",
        message:
          "Questions are already organized with one question per cluster.",
      });
    } else {
      setDialogState({
        open: true,
        type: "confirm",
        title: "Confirmation",
        message: `This will replace existing clusters and create a total of ${countTotalQuestions()} clusters (one cluster per question). Do you want to continue?`,
      });
    }
  };

  const handleClose = () => {
    setDialogState((prev) => ({ ...prev, open: false }));
  };

  const countTotalQuestions = () => {
    return clusters.reduce((total, cluster) => {
      return total + cluster.questions.filter(isValidQuestion).length;
    }, 0);
  };

  const handleConfirm = () => {
    const allQuestions = clusters.flatMap((cluster) =>
      cluster.questions.filter(isValidQuestion)
    );

    const newClusters = allQuestions.map((question, index) => ({
      id: Math.random().toString(36).substr(2, 9),
      name: `Cluster ${index + 1}`,
      questions: [question],
    }));

    setClusters(newClusters);
    setSelectedClusterIndex(0);
    setSelectedQuestionIndex(0);
    handleClose();
  };

  const getAbsoluteQuestionIndex = (
    clusterIndex: number,
    questionIndex: number
  ) => {
    return (
      clusters.slice(0, clusterIndex).reduce((sum, cluster) => {
        const validQuestions = cluster.questions.filter(
          (question) => question.text.trim() !== ""
        );
        return sum + validQuestions.length;
      }, 0) + questionIndex
    );
  };
  const scrollAllSections = (absoluteQuestionIndex: number) => {
    setTimeout(() => {
      const scrollOptions = {
        behavior: "smooth" as ScrollBehavior,
        block: "start" as ScrollLogicalPosition,
      };

      const editorRef = editorRefs.current[absoluteQuestionIndex];
      const previewElements = Array.from(
        document.querySelectorAll(".preview-question-box")
      );
      const previewRef =
        previewElements[selectedClusterIndex - selectedQuestionIndex] ||
        previewElements[absoluteQuestionIndex - 1];

      if (editorRef) {
        editorRef.scrollIntoView(scrollOptions);
      }

      if (previewRef) {
        previewRef.scrollIntoView(scrollOptions);
      }

      setTimeout(() => {
        const questionTextField =
          inputRefs.current[
            `question-text-${selectedClusterIndex}-${selectedQuestionIndex}`
          ];
        if (questionTextField) {
          questionTextField.focus();
        }
      }, 100);
    }, 0);
  };

  useEffect(() => {
    setClusters([createEmptyCluster()]);
  }, []);

  useEffect(() => {
    const absoluteIndex = getAbsoluteQuestionIndex(
      selectedClusterIndex,
      selectedQuestionIndex
    );
    scrollAllSections(absoluteIndex);
  }, [selectedQuestionIndex, selectedClusterIndex]);

  useEffect(() => {
    if (clusters[selectedClusterIndex]?.questions[selectedQuestionIndex]) {
      const questionTextElement = document.getElementById(
        `question-text-${selectedClusterIndex}-${selectedQuestionIndex}`
      );
      if (questionTextElement) {
        questionTextElement.focus();
      }
    }
  }, [selectedClusterIndex, selectedQuestionIndex, clusters]);

  const replaceBase64WithUploadedUrl = (
    htmlContent: string,
    imgMap: Record<string, string>
  ): string => {
    let processedHtml = htmlContent;
  
    for (const [base64Image, uploadedUrl] of Object.entries(imgMap)) {
      const escapedBase64 = base64Image.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      const imgTagPattern = new RegExp(
        `<img[^>]*src=["']${escapedBase64}["'][^>]*>`,
        "g"
      );
  
      processedHtml = processedHtml.replace(imgTagPattern, (match) =>
        match.replace(
          new RegExp(`src=["']${escapedBase64}["']`),
          `width="100%" src="${uploadedUrl}"`
        )
      );
    }
  
    return processedHtml;
  };
  

  const base64ToBlob = (base64Data: string, fileName: string): File => {
    const [header, content] = base64Data.split(",");
    const mimeMatch = header.match(/^data:(.*?);/);
    const mimeType = mimeMatch ? mimeMatch[1] : "image/png";
  
    const binaryStr = window.atob(content);
    const len = binaryStr.length;
    const arr = new Uint8Array(len);
  
    for (let i = 0; i < len; i++) {
      arr[i] = binaryStr.charCodeAt(i);
    }
  
    const blob = new Blob([arr], { type: mimeType });
    return new File([blob], fileName, { type: mimeType });
  };

  const processHtmlWithImageRestrictions = async (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const imgElements = doc.getElementsByTagName("img");
    const uploadedImgMap: Record<string, string> = {};

    for (const img of Array.from(imgElements)) {
      const base64Image = img.getAttribute("src");
      if (base64Image && base64Image.startsWith("data:image")) {
        try {
          const mimeMatch = base64Image.match(/^data:image\/(png|jpeg|jpg|gif);base64,/);
          const fileExtension = mimeMatch ? mimeMatch[1] : "png";
          const now = new Date();
          const formattedDate = `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, "0")}${String(now.getDate()).padStart(2, "0")}${String(now.getHours()).padStart(2, "0")}${String(now.getMinutes()).padStart(2, "0")}${String(now.getSeconds()).padStart(2, "0")}`;
          const fileName = `${formattedDate}.${fileExtension}`;

          const imageFile = base64ToBlob(base64Image, fileName);
          const res = await apiRequest({
            form: true,
            api: allApis.file_upload,
            payload: {
              parent_id: selectedClusterIndex,
              file: imageFile,
              filename: fileName,
              created_by: userData?.id,
              modified_by: userData?.id,
              parent_model: selectedQuestionIndex,
            },
          });

          if (res.file) {
            uploadedImgMap[base64Image] = res.file;
          }
        } catch (error) {
          console.error("Upload error:", error);
        }
      }
    }

    return uploadedImgMap;
  };


  const parseQuestionContent = (
    questionContent: string,
    uploadedImgMap: Record<string, string>
  ): Partial<Question> => {
    const question: Partial<Question> = {
      id: Math.random().toString(36).substr(2, 9),
      options: [],
      solutionHtml: "",
      explanation: "",
      questionHtml: "",
    };
  
    try {
      // Extract question text
      const questionTextMatch = questionContent.match(/^\s*(.*?)(?=Options:)/s);
      if (questionTextMatch) {
        question.text = questionTextMatch[1].trim();
        question.questionHtml = `<p>${question.text}</p>`;
      }
  
      // Extract options
      const optionsText = questionContent.match(
        /Options:.*?(?=Correct(?:\s*Answer:|[\s\n]*<strong>\s*Answer<\/strong>:|[\s\n]*Answer:)|$)/s
      )?.[0] || "";
      const optionsMatch = optionsText.match(/\(([a-d])\)(.*?)(?=\([a-d]\)|$)/g);
  
      if (optionsMatch) {
        question.options = optionsMatch.map((opt) => {
          let optionText = opt.replace(/^\([a-d]\)/, "").trim();
          // Replace base64 images in options
          optionText = replaceBase64WithUploadedUrl(optionText, uploadedImgMap);
          return optionText;
        });
      }
  
      // Extract correct answer
      const correctAnswerMatch = questionContent.match(
        /Correct(?:\s*Answer:|[\s\n]*<strong>\s*Answer<\/strong>:|[\s\n]*Answer:)\s*\(([a-d])\)/i
      );
      if (correctAnswerMatch) {
        question.correctAnswer = correctAnswerMatch[1].toLowerCase().charCodeAt(0) - 97;
      }
  
      // Extract solution
      const solutionMatch = questionContent.match(
        /(?:Solution:|<strong>Solution:<\/strong>)(.*)$/s
      );
      if (solutionMatch) {
        question.solutionHtml = solutionMatch[1].trim();
      }
  
      // Replace base64 images in questionHtml and solutionHtml
      if (question.questionHtml) {
        question.questionHtml = replaceBase64WithUploadedUrl(
          question.questionHtml,
          uploadedImgMap
        );
      }
      if (question.solutionHtml) {
        question.solutionHtml = replaceBase64WithUploadedUrl(
          question.solutionHtml,
          uploadedImgMap
        );
      }
    } catch (error) {
      console.error("Error parsing question content:", error);
    }
  
    return question;
  };
  const handleSmartPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text");

    const cleanText = text.replace(/\*\*/g, "").replace(/\*/g, "");

    const newQuestion: Partial<Question> = {
      id: Math.random().toString(36).substr(2, 9),
      options: [],
      solutionHtml: "",
      explanation: "",
      questionHtml: "",
    };

    const questionMatch = cleanText.match(
      /Question\s*\d+\.\s*(.*?)(?=Options:)/s
    );
    if (questionMatch) {
      newQuestion.text = questionMatch[1].trim();
      newQuestion.questionHtml = `<p>${newQuestion.text}</p>`;
    }

    const optionsText =
      cleanText.match(/Options:.*?(?=Correct Answer:|$)/s)?.[0] || "";
    const optionsMatch = optionsText.match(/\([a-d]\)[^()]+/gi);

    if (optionsMatch) {
      newQuestion.options = optionsMatch.map((opt) =>
        opt.replace(/^\([a-d]\)/, "").trim()
      );
    }

    // Extract correct answer
    const correctAnswerMatch = cleanText.match(
      /Correct Answer:\s*\(([a-d])\)/i
    );
    if (correctAnswerMatch) {
      newQuestion.correctAnswer =
        correctAnswerMatch[1].toLowerCase().charCodeAt(0) - 97;
    }

    // Extract solution
    const solutionMatch = cleanText.match(
      /Solution:(.*?)(?=\(this is my question|$)/s
    );
    if (solutionMatch) {
      const solutionText = solutionMatch[1].trim();
      newQuestion.explanation = solutionText;

      // Process bullet points and create HTML
      const solutionLines = solutionText
        .split("\n")
        .map((line) => line.trim())
        .filter(Boolean);

      let solutionHtml = "<div>";
      let inList = false;

      solutionLines.forEach((line) => {
        if (line.startsWith("*")) {
          if (!inList) {
            solutionHtml += "<ul>";
            inList = true;
          }
          const bulletPoint = line.substring(1).trim();
          solutionHtml += `<li>${bulletPoint}</li>`;
        } else {
          if (inList) {
            solutionHtml += "</ul>";
            inList = false;
          }
          solutionHtml += `<p>${line}</p>`;
        }
      });

      if (inList) {
        solutionHtml += "</ul>";
      }
      solutionHtml += "</div>";

      newQuestion.solutionHtml = solutionHtml;
    }

    // Only update if we have valid question content
    if (
      newQuestion.text &&
      Array.isArray(newQuestion.options) &&
      newQuestion.options.length > 0 &&
      newQuestion.correctAnswer !== undefined
    ) {
      
      clusters[selectedClusterIndex].questions[clusters[selectedClusterIndex].questions.length-1] =
        newQuestion as Question;
      console.log(clusters, "hehehehehehe")
      addNewQuestion(selectedClusterIndex);
      
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    clusterIndex?: number
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;
  
    setHasFileUploaded(true);
  
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        // Convert Word document to HTML
        const result = await mammoth.convertToHtml({
          arrayBuffer: e.target?.result as ArrayBuffer,
        });
        let htmlContent = result.value;
        htmlContent = htmlContent
          .replace(/<br\s*\/?>/gi, "\n")
          .replace(/\*\*/g, "")
          .replace(/\s+/g, " ")
          .trim();
  
        const sections = htmlContent.split(/Cluster \d+\.\d+/);
        const clusterNumbers = htmlContent.match(/Cluster (\d+\.\d+)/g) || [];
  
        const newClusters: Cluster[] = [];
  
        const uploadedImgMap = await processHtmlWithImageRestrictions(htmlContent);
  
        clusterNumbers.forEach((clusterHeader, index) => {
          const clusterNumber = clusterHeader.match(/Cluster (\d+\.\d+)/)?.[1];
          const clusterContent = sections[index + 1];
  
          if (!clusterContent) return;
  
          const cluster: Cluster = {
            id: Math.random().toString(36).substr(2, 9),
            name: `Cluster ${clusterNumber}`,
            questions: [],
          };

          const questionSegments = clusterContent.split(/Question \d+\./);
          const questions = questionSegments.filter((q) => q.trim());
  
          questions.forEach((questionContent) => {
            const question = parseQuestionContent(questionContent, uploadedImgMap);
  
            if (
              question.text &&
              question.options?.length === 4 &&
              question.correctAnswer !== undefined
            ) {
              cluster.questions.push(question as Question);
            }
          });
  
          if (cluster.questions.length > 0) {
            newClusters.push(cluster);
          }
        });
  
        // Update clusters and selected indices
        setClusters(newClusters);
        setSelectedClusterIndex(clusterIndex || 0);
        setSelectedQuestionIndex(0);
      } catch (error) {
        console.error("Error parsing file:", error);
      }
    };
  
    reader.readAsArrayBuffer(file);
  };
  

  const addNewCluster = () => {
    setClusters((prev) => [...prev, createEmptyCluster()]);
    setSelectedClusterIndex(clusters.length);
    setSelectedQuestionIndex(0);
  };

  const addNewQuestion = (clusterIndex: number) => {
    const lastQuestion =
      clusters[clusterIndex].questions[clusters[clusterIndex].questions.length - 1];
  
    // Validate if the last question is not empty or invalid
    if (!lastQuestion || !lastQuestion.text.trim() || !lastQuestion.options.some((opt) => opt)) {
      alert("The last question in the cluster must be valid before adding a new question.");
      return;
    }
  
    const newClusters = [...clusters];
    const newQuestion = createEmptyQuestion();
    newClusters[clusterIndex].questions.push(newQuestion);
    console.log("newClusters", newClusters);
  
    const newQuestionIndex = newClusters[clusterIndex].questions.length - 1;
    console.log("newQuestionIndex", newQuestionIndex);
  
    setClusters(newClusters);
    setSelectedClusterIndex(clusterIndex);
  
    const absoluteQuestionIndex = getAbsoluteQuestionNumber(clusterIndex, newQuestionIndex);
    setSelectedQuestionIndex(absoluteQuestionIndex);
    console.log("selectedQuestionIndex", absoluteQuestionIndex);
  
    // Use MutationObserver to ensure the DOM is updated
    const observer = new MutationObserver((mutationsList, observer) => {
      const newQuestionElement = document.getElementById(`question-editor-${absoluteQuestionIndex}`);
      if (newQuestionElement) {
        observer.disconnect(); // Stop observing once the element is found
        newQuestionElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        console.log("newQuestionElement", newQuestionElement, absoluteQuestionIndex);
      }
    });
  
    // Start observing the document for child changes
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  
    // Scroll preview panel
    setTimeout(() => {
      const previewElements = Array.from(document.querySelectorAll(".preview-question-box"));
      
  
      const selectedQuestionElement = previewElements[selectedQuestionIndex-1];
      if (selectedQuestionElement) {
        selectedQuestionElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 200);
  };
  
  

  const getValidQuestionsCount = (questions: Question[]) => {
    return questions.filter(
      (q) => q.text.trim() || q.options.some((opt) => opt.trim())
    ).length;
  };

  const handleSubmit = () => {
    const allQuestions = clusters.flatMap((cluster) => cluster.questions);
    onSubmit(allQuestions);
  };

  dialogRef.current = {
    onSubmitForm: handleSubmit,
  };

  const updateQuestion = (
    clusterIndex: number,
    questionIndex: number,
    updatedQuestion: Question
  ) => {
    const newClusters = [...clusters];
    newClusters[clusterIndex].questions[questionIndex] = updatedQuestion;
    console.log("i m data: ", newClusters);
    setClusters(newClusters);
  };

  const getAbsoluteQuestionNumber = (
    clusterIndex: number,
    questionIndex: number
  ) => {
    const previousValidQuestions = clusters
      .slice(0, clusterIndex)
      .reduce((sum, cluster) => {
        return (
          sum +
          cluster.questions.filter(
            (q) => q.text.trim() || q.options.some((opt) => opt.trim())
          ).length
        );
      }, 0);

    const currentClusterQuestions = questionIndex + 1;

    return previousValidQuestions + currentClusterQuestions;
  };
  

  // Ashish Singh - Startss here

  // const base64ToBlob = (base64: string, fileName: string) => {
  //   const byteString = atob(base64.split(",")[1]);
  //   const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];

  //   const arrayBuffer = new ArrayBuffer(byteString.length);
  //   const uint8Array = new Uint8Array(arrayBuffer);

  //   for (let i = 0; i < byteString.length; i++) {
  //     uint8Array[i] = byteString.charCodeAt(i);
  //   }

  //   return new File([uint8Array], fileName, { type: mimeString });
  // };

  // useEffect(() => {
  //     if (ref.current) {
  //       const imageRefs = ref.current.querySelectorAll("img");
  //       const imageSrc: string[] = [];
  //       const imageBlobs: Blob[] = [];

  //       console.log("imagesRef = ", imageRefs);
  //       console.log("imageRef src = ", imageSrc);
  //       console.log("imageRef blobs = ", imageBlobs);

  //       imageRefs.forEach((imageRef) => {
  //         const imageBlob = base64ToBlob(imageRef.src);
  //         imageBlobs.push(imageBlob);
  //         imageSrc.push(imageRef.src);
  //       });

  //       imageBlobs.forEach(async(imageBlob, index) => {
  //         const formData = new FormData();
  //         formData.append("image", imageBlob, `image${index}.png`);

  //         // Send to backend
  //         try {
  //           const res = await apiRequest({
  //             form: true,
  //             api: allApis.file_upload,
  //             payload: {
  //               parent_id: selectedClusterIndex,
  //               file: imageBlobs[0],
  //               created_by: userData?.id,
  //               modified_by: userData?.id,
  //               parent_model:selectedQuestionIndex,
  //             }}
  //           );

  //           const result = await res.json();
  //           console.log("Upload success:", result);
  //         } catch (error) {
  //           console.error("Upload error:", error);
  //         }
  //       });
  //     }

  // }, [clusters]);

  // Ashish Singh - Ends here

  useEffect(() => {
    // Update editor elements for the current cluster
    const elements = document.querySelectorAll('[id^="question-editor-"]');
    setEditorElements(elements);
  }, [selectedClusterIndex, selectedQuestionIndex, clusters]);

  const handleQuestionSelect = (clusterIndex: number, questionIndex: number) => {
    setSelectedClusterIndex(clusterIndex);
    setSelectedQuestionIndex(questionIndex);

    // Scroll to the correct editor element in the current cluster
    setTimeout(() => {
      const targetEditor = document.getElementById(`question-editor-${questionIndex+1}`);
    
      console.log("targetEditor", targetEditor);

      if (targetEditor) {
        targetEditor.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 500);

    // Scroll preview panel
    setTimeout(() => {
      const previewElements = Array.from(
        document.querySelectorAll(".preview-question-box")
      );

      const selectedQuestionElement = previewElements[questionIndex];
      if (selectedQuestionElement) {
        selectedQuestionElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 200);
  };

  

  useEffect(() => {
    if (ref.current) {
      const images = ref.current.querySelectorAll("img");

      console.log(images);
    }
  }, []);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {/* Header */}
      <Box sx={{ p: 2, borderBottom: "1px solid #e0e0e0" }}>
        <Breadcrumbs separator={<NavigateNextOutlined fontSize="small" />}>
          <Link
            component="button"
            variant="body1"
            onClick={onClose}
            sx={{
              textDecoration: "none",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            Questions
          </Link>
          <Typography color="text.primary">Add New Question</Typography>
        </Breadcrumbs>
      </Box>

      <Box sx={{ flex: 1, display: "flex", overflow: "hidden" }}>
        {/* Left Panel - Questions List */}
        <Paper
          sx={{
            width: 280,
            overflow: "auto",
            borderRight: 1,
            borderColor: "divider",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 1,
              marginTop: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                display: "inline-block",
                width: "99%",
                padding: "6px 4px",
                borderRadius: "6px",
                marginBottom: "4px",
                textAlign: "center",
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
              }}
              onClick={handleOpen}
            >
              Put Every Question in Separate Cluster
            </Button>
            <Dialog open={dialogState.open} onClose={handleClose}>
              <DialogTitle>{dialogState.title}</DialogTitle>
              <DialogContent>
                <DialogContentText>{dialogState.message}</DialogContentText>
              </DialogContent>
              <DialogActions>
                {dialogState.type === "confirm" ? (
                  <>
                    <Button
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                    >
                      No
                    </Button>
                    <Button
                      onClick={handleConfirm}
                      color="primary"
                      autoFocus
                      variant="contained"
                    >
                      Yes
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={handleClose}
                    color="primary"
                    variant="contained"
                  >
                    OK
                  </Button>
                )}
              </DialogActions>
            </Dialog>

            <input
              type="file"
              accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              id="upload-word-file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(e) => handleFileUpload(e, selectedClusterIndex)}
            />
            <label
              htmlFor="upload-word-file"
              style={{
                display: "inline-block",
                width: "90%",
                padding: "8px 16px",
                borderRadius: "8px",
                backgroundColor: "black",
                color: "#fff",
                textAlign: "center",
                cursor: "pointer",
                fontSize: "16px",
                fontWeight: "500",
                textTransform: "none",
              }}
            >
              Import From File
            </label>
          </Box>
          <List>
            {clusters.map((cluster, clusterIndex) => (
              <React.Fragment key={cluster.id}>
                <ListItem
                  sx={{
                    backgroundColor:
                      selectedClusterIndex === clusterIndex
                        ? "rgba(25, 118, 210, 0.08)"
                        : "transparent",
                  }}
                >
                  <ListItemText
                    primary={
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          {cluster.name}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            backgroundColor: "primary.main",
                            color: "white",
                            borderRadius: "12px",
                            px: 1,
                            py: 0.5,
                          }}
                        >
                          {cluster.questions.filter(isValidQuestion).length}{" "}
                          Questions
                        </Typography>
                      </Stack>
                    }
                  />
                </ListItem>
                {cluster.questions
                  .filter(isValidQuestion)
                  .map((question, questionIndex) => (
                    <ListItem
                      key={question.id}
                      button
                      selected={
                        selectedClusterIndex === clusterIndex &&
                        selectedQuestionIndex ===
                          getAbsoluteQuestionNumber(
                            clusterIndex,
                            questionIndex
                          ) -
                            1
                      }
                      onClick={() =>
                        handleQuestionSelect(
                          clusterIndex,
                          getAbsoluteQuestionNumber(
                            clusterIndex,
                            questionIndex
                          ) - 1
                        )
                      }
                      sx={{
                        pl: 4,
                        "&.Mui-selected": {
                          backgroundColor: "lightgrey",
                        },
                        "&:hover": {
                          backgroundColor: "grey",
                        },
                      }}
                    >
                      <ListItemText
                        primary={
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <span>{`Q${getAbsoluteQuestionNumber(
                              clusterIndex,
                              questionIndex
                            )}.`}</span>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: `${
                                  question.questionHtml.slice(0, 30) ||
                                  `Question ${questionIndex + 1}`
                                }${question.text.length > 30 ? "..." : ""}`,
                              }}
                            />
                          </Box>
                        }
                      />
                    </ListItem>
                  ))}
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Paper>

        {/* Center Panel - Editor */}
        <Box
          sx={{
            flex: 1,
            overflow: "auto",
            bgcolor: "background.default",
            p: 3,
          }}
          onPaste={handleSmartPaste}
        >
          {
            clusters[selectedClusterIndex]?.questions.map((question, qIndex) => (
              <QuestionEditor
                key={question.id}
                question={question}
                questionNumber={getAbsoluteQuestionNumber(
                  selectedClusterIndex,
                  qIndex
                )}
                onQuestionChange={(updatedQuestion) =>
                  updateQuestion(selectedClusterIndex, qIndex, updatedQuestion)
                }
                fileInputRef={fileInputRef}
                isSelected={
                  selectedQuestionIndex + 1 ===
                          getAbsoluteQuestionNumber(
                            selectedClusterIndex,
                            qIndex
                          )
                }
              />
            ))
          }
        </Box>

        {/* Right Panel - Preview */}
        <Paper
          sx={{
            width: 400,
            p: 3,
            overflow: "auto",
            borderLeft: 1,
            borderColor: "divider",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Question Preview
          </Typography>
          {hasFileUploaded && (
            <Box
              ref={ref}
              sx={{ height: "calc(100vh - 200px)", overflow: "auto" }}
            >
              {clusters.map((cluster, cidx) =>
                cluster?.questions
                  .filter(
                    (question) =>
                      question.text.trim() &&
                      question.options.some((opt) => opt)
                  )
                  .map((question, index) => (
                    <QuestionPreview
                      key={question.id}
                      question={question}
                      questionNumber={getAbsoluteQuestionIndex(cidx, index)}
                      isSelected={
                        getAbsoluteQuestionNumber(cidx, index) - 1 ===
                        selectedQuestionIndex
                      }
                      onClick={() =>
                        setSelectedQuestionIndex(
                          getAbsoluteQuestionNumber(cidx, index) - 1
                        )
                      }
                    />
                  ))
              )}
            </Box>
          )}
        </Paper>
      </Box>

      {/* Footer */}
      <Box
        sx={{
          p: 2,
          borderTop: 1,
          borderColor: "divider",
          bgcolor: "background.paper",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          <Box>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => addNewQuestion(selectedClusterIndex)}
            >
              Add Question to {clusters[selectedClusterIndex]?.name}
            </Button>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={addNewCluster}
            >
              Add New Cluster
            </Button>

            <Button variant="contained" onClick={handleSubmit}>
              Save all Questions and Clusters to Draft
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
